import { effect, Injectable, signal, WritableSignal } from '@angular/core';
import {CompanyBranchReference} from "@core/models/company.model";
import {MapType} from "@core/services/map.service";

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  constructor() {}

  private storedSignal<T>(key: string, initialValue: T): WritableSignal<T> {
    const storedValue = localStorage.getItem(key);
    const parsedValue = storedValue ? JSON.parse(storedValue) : initialValue;
    const signalData = signal<T>(parsedValue);
    effect(() => {
      const newValue = signalData();
      localStorage.setItem(key, JSON.stringify(newValue));
    });

    return signalData;
  }
  demoSignal = this.storedSignal<string>('demo', '');
  branchMapPreferenceSignal = this.storedSignal<CompanyBranchReference|null>('branchMapPreference', null);
  locationSignal = signal<any>(null);
  tileSignal = this.storedSignal<MapType>('mapType','osm')
}
